import imageBlobReduce from 'image-blob-reduce';

import { getService } from '..';
import { BaseService } from '../base-service';
import { parameters } from '../parameters';
import { IEditableParty, IParty, IPartySettings, IPublicContact, IPublicRsvp } from '../types';

import { partySlice } from './reducer';

import { routerNavigate } from '#root/Components/ExposeRouterNavigate';
import { api } from '#root/utils/api';

const NEW = 'new' as const;
type ExtraFields = Pick<
  IEditableParty,
  'type' | 'title' | 'description' | 'date' | 'body' | 'heroImage' | 'language' | 'location'
>;
const createParty = (fields: ExtraFields): IEditableParty => {
  return {
    id: NEW,
    sid: '',
    cohostInvites: [],
    actions: {},
    cohosts: [],
    createdAt: new Date().toISOString(),
    rsvpFields: [],
    rsvpEnabled: true,
    rsvpDeadlineEnabled: true,
    guestCountChoiceEnabled: true,
    owner: 'new',
    smsUseSenderNumber: true,
    messageTemplates: [],
    modifiedAt: new Date().toISOString(),
    themeName: 'default',
    ...fields,
  };
};

export class PartyService extends BaseService {
  public load(sidOrSlug: string) {
    if (
      this.store.getState().party.party?.sid === sidOrSlug ||
      this.store.getState().party.party?.slug === sidOrSlug
    ) {
      return Promise.resolve();
    }

    this.store.dispatch(partySlice.actions.clearParty());
    // For faster loading times on party pages, the party data is provided on first request.
    const params = new URLSearchParams(window.location.search);
    const contactId = params.get('cid')?.replace(' .', '') ?? null;
    const rsvpId = params.get('rid');

    if (parameters.party?.sid === sidOrSlug || parameters.party?.slug === sidOrSlug) {
      const party = parameters.party as IParty;
      this.loadCurrentlyViewingContact(party.id, contactId, rsvpId);
      this.store.dispatch(partySlice.actions.updateParty({ party }));

      return Promise.resolve();
    }

    return api
      .get(`/api/party/${sidOrSlug}`)
      .json()
      .then((party: IEditableParty) => {
        this.loadCurrentlyViewingContact(party.id, contactId, rsvpId);
        this.store.dispatch(partySlice.actions.updateParty({ party }));
      });
  }
  public refetch(): Promise<void> {
    const sid = this.store.getState().party.party?.sid;

    if (!sid) {
      return Promise.resolve();
    }

    return api
      .get(`/api/party/${sid}`)
      .json()
      .then((party: IEditableParty) => {
        this.store.dispatch(partySlice.actions.updateParty({ party }));
      });
  }
  public createNew(extraFields: Omit<ExtraFields, 'language'>) {
    this.store.dispatch(
      partySlice.actions.updateParty({
        party: createParty({
          ...extraFields,
          language: getService('translation').currentLanguage(),
        }),
      })
    );
    this.toggleEditMode();
    this.store.dispatch(partySlice.actions.updateCurrentlyViewingContact({ contact: null }));
  }
  public cancelWizard() {
    const id = this.store.getState().party.party?.id;

    if (id === NEW) {
      this.store.dispatch(partySlice.actions.clearParty());
    }
  }
  public updateField<T extends keyof IEditableParty>(field: T, value: unknown) {
    this.store.dispatch(partySlice.actions.updateField({ field, value }));
  }
  public toggleEditMode() {
    this.store.dispatch(partySlice.actions.toggleEditMode());
  }
  public cancelUpdates() {
    const originalParty = this.store.getState().party.originalParty;
    if (originalParty) {
      this.store.dispatch(partySlice.actions.updateParty({ party: originalParty }));
    }
  }
  public allowedMimeTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp'];
  public uploadImage = async (image: File): Promise<string> => {
    const id = this.store.getState().party.party?.id;
    if (!id) {
      return Promise.reject();
    }
    const { type } = image;

    if (!this.allowedMimeTypes.includes(type)) {
      return Promise.reject(`Type ${type} is not allowed`);
    }

    this.store.dispatch(partySlice.actions.setGlobalLoading({}));

    // Do in browser resizing prior to uploading to save some bandwidth.
    let resizedBlob: Blob | File;
    try {
      resizedBlob = await imageBlobReduce().toBlob(image, { max: 1000 });
      console.log('Resized image in browser prior to uploading');
    } catch (e) {
      console.error('Failed to resize image in browser');
      resizedBlob = image;
    }

    const data = new FormData();
    data.append('image', resizedBlob);

    return api
      .post(`/api/party/${id}/image`, { body: data, timeout: 30000 })
      .json<string>()
      .finally(() => {
        this.store.dispatch(partySlice.actions.setGlobalLoading({ isLoading: false }));
      });
  };
  public async saveSetting(field: keyof IPartySettings, value: unknown) {
    const { party } = this.store.getState().party;
    if (!party) {
      throw new Error('No party stored.');
    }
    const responseParty = await api
      .patch(`/api/party/${party.id}`, {
        json: {
          [field]: value,
        },
      })
      .json<IEditableParty>();
    this.store.dispatch(partySlice.actions.updateParty({ party: responseParty }));
  }
  public async save() {
    const { party, modifiedFields } = this.store.getState().party;
    if (!party) {
      throw new Error('No party stored.');
    }

    if (modifiedFields.title !== undefined && modifiedFields.title.length === 0) {
      return;
    }

    if (party.id === NEW) {
      // Check if we are logged in, otherwise show a signup dialog.
      const { currentUser } = this.store.getState().app;

      if (!currentUser) {
        const user = await getService('app').promptLogin();
        if (!user) {
          return;
        }
      }

      await api
        .post('/api/party', { json: party })
        .json<IEditableParty>()
        .then(data => routerNavigate(`/${data.sid}`));
      this.toggleEditMode();
    } else {
      const responseParty = await api
        .patch(`/api/party/${party.id}`, { json: modifiedFields })
        .json<IEditableParty>();
      this.store.dispatch(partySlice.actions.updateParty({ party: responseParty }));
    }
  }
  public async updateCurrentlyViewingRsvp(rsvp: IPublicRsvp) {
    this.store.dispatch(partySlice.actions.updateCurrentlyViewingRsvp({ rsvp }));
  }
  public async loadCurrentlyViewingContact(
    partyId: IParty['id'],
    contactId: IPublicContact['id'] | null,
    rsvpId: IPublicRsvp['id'] | null
  ) {
    if ((!contactId || contactId === 'test') && !rsvpId) {
      this.store.dispatch(partySlice.actions.updateCurrentlyViewingContact({ contact: null }));
      this.store.dispatch(partySlice.actions.updateCurrentlyViewingRsvp({ rsvp: null }));
      return;
    }

    const response = await api
      .get(
        contactId
          ? `/api/party/${partyId}/contact-rsvp?cid=${contactId}`
          : `/api/party/${partyId}/contact-rsvp?rid=${rsvpId}`
      )
      .json<{ contact: IPublicContact | null; rsvp: IPublicRsvp | null }>()
      .catch(error => {
        this.store.dispatch(partySlice.actions.updateCurrentlyViewingContact({ contact: null }));
        this.store.dispatch(partySlice.actions.updateCurrentlyViewingRsvp({ rsvp: null }));
        console.error(error);
      });

    this.store.dispatch(
      partySlice.actions.updateCurrentlyViewingContact({ contact: response?.contact ?? null })
    );
    this.store.dispatch(
      partySlice.actions.updateCurrentlyViewingRsvp({ rsvp: response?.rsvp ?? null })
    );
  }
  public unload() {
    this.store.dispatch(partySlice.actions.unload());
  }

  public redeemCoupon(coupon: string): Promise<void> {
    const id = this.store.getState().party.party?.id;

    if (!id) {
      return Promise.resolve();
    }

    return api
      .post(`/api/party/${id}/redeem-coupon`, { json: { coupon } })
      .json()
      .then((party: IEditableParty) => {
        this.store.dispatch(partySlice.actions.updateParty({ party }));
      });
  }
}
