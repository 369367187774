/*
Generated model based on translations `npm run update:translations`
*/
export const Keys = {
  symfony: {
    generic: {
      email_from: 'symfony.generic.email_from',
      unauthorized: 'symfony.generic.unauthorized',
    },
    meta: { description: 'symfony.meta.description', title: 'symfony.meta.title' },
    email: {
      send_by: 'symfony.email.send_by',
      unsubscribe: 'symfony.email.unsubscribe',
      link: 'symfony.email.link',
      subject: 'symfony.email.subject',
      button: { rsvp: 'symfony.email.button.rsvp', no_rsvp: 'symfony.email.button.no_rsvp' },
    },
    text: { validation_text: 'symfony.text.validation_text' },
    payment: {
      unlimited_photos: { name: 'symfony.payment.unlimited_photos.name' },
      token: { name: 'symfony.payment.token.name' },
      plan: {
        premium: { name: 'symfony.payment.plan.premium.name' },
        pro: { name: 'symfony.payment.plan.pro.name' },
      },
    },
    send_test: { name: 'symfony.send_test.name' },
    error: {
      user_not_verified: 'symfony.error.user_not_verified',
      user_not_created: 'symfony.error.user_not_created',
      user_found_login_instead: 'symfony.error.user_found_login_instead',
      limit_contacts_exceeded: 'symfony.error.limit_contacts_exceeded',
    },
    wishlist: { already_reserved: 'symfony.wishlist.already_reserved' },
  },
  menu: {
    party: {
      title: 'menu.party.title',
      frontpage: 'menu.party.frontpage',
      contacts: 'menu.party.contacts',
      invitations: 'menu.party.invitations',
      rsvps: 'menu.party.rsvps',
      settings: 'menu.party.settings',
      wishlist: 'menu.party.wishlist',
      photos: 'menu.party.photos',
      upgrade: 'menu.party.upgrade',
    },
    title: 'menu.title',
    create: { desktop: 'menu.create.desktop', mobile: 'menu.create.mobile' },
    parties: 'menu.parties',
    account: 'menu.account',
    help: 'menu.help',
    logout: 'menu.logout',
    login: 'menu.login',
  },
  plan: {
    premium: { name: 'plan.premium.name', label: 'plan.premium.label' },
    pro: { name: 'plan.pro.name' },
    free: { name: 'plan.free.name' },
  },
  chooser: {
    title: 'chooser.title',
    current_plan: 'chooser.current_plan',
    downgrade_notice: 'chooser.downgrade_notice',
    upgrade_to: 'chooser.upgrade_to',
    free: { name: 'chooser.free.name', list: 'chooser.free.list' },
    premium: { name: 'chooser.premium.name', list: 'chooser.premium.list' },
    pro: {
      name: 'chooser.pro.name',
      list: 'chooser.pro.list',
      subtracted_from_premium: 'chooser.pro.subtracted_from_premium',
    },
    before_price: 'chooser.before_price',
  },
  redeem_coupon: {
    field: 'redeem_coupon.field',
    enabler_button: 'redeem_coupon.enabler_button',
    change_button: 'redeem_coupon.change_button',
    button: 'redeem_coupon.button',
    error: 'redeem_coupon.error',
  },
  login: {
    prompt: { title: 'login.prompt.title' },
    login: 'login.login',
    signup: 'login.signup',
    forgot_password: 'login.forgot_password',
    not_found: 'login.not_found',
    facebook: 'login.facebook',
    google: 'login.google',
    or: 'login.or',
    with_email: 'login.with_email',
    remember_me: 'login.remember_me',
    recover: {
      title: 'login.recover.title',
      success: 'login.recover.success',
      action: 'login.recover.action',
    },
    already_have_account: 'login.already_have_account',
  },
  signup: {
    facebook: 'signup.facebook',
    google: 'signup.google',
    error_terms: 'signup.error_terms',
    terms: 'signup.terms',
    create_account: 'signup.create_account',
    password: { error: 'signup.password.error', helper: 'signup.password.helper' },
  },
  verify: {
    phone: {
      error: 'verify.phone.error',
      description: 'verify.phone.description',
      title: 'verify.phone.title',
      action: 'verify.phone.action',
      description2: 'verify.phone.description2',
    },
  },
  page: {
    parties: {
      title: 'page.parties.title',
      create: 'page.parties.create',
      cohost: 'page.parties.cohost',
    },
    account: {
      password: {
        title: 'page.account.password.title',
        current_password: 'page.account.password.current_password',
        new_password: 'page.account.password.new_password',
        confirm_new_password: 'page.account.password.confirm_new_password',
        action: 'page.account.password.action',
      },
      verify_number: 'page.account.verify_number',
      marketing_consent: 'page.account.marketing_consent',
      remove_user: {
        title: 'page.account.remove_user.title',
        description: 'page.account.remove_user.description',
        textfield_label: 'page.account.remove_user.textfield_label',
        textfield_value: 'page.account.remove_user.textfield_value',
        confirm: 'page.account.remove_user.confirm',
      },
      user: 'page.account.user',
      resend_verification: 'page.account.resend_verification',
      notifications: 'page.account.notifications',
      mute_rsvps: 'page.account.mute_rsvps',
      mute_rsvp_messages: 'page.account.mute_rsvp_messages',
    },
    not_found: 'page.not_found',
  },
  party: {
    blocks: {
      date: {
        title: 'party.blocks.date.title',
        notice: 'party.blocks.date.notice',
        notice_deletion: 'party.blocks.date.notice_deletion',
      },
      hero: { remove: 'party.blocks.hero.remove' },
      map: {
        description: 'party.blocks.map.description',
        remove: 'party.blocks.map.remove',
        label: 'party.blocks.map.label',
        route: 'party.blocks.map.route',
      },
      rsvp: {
        title: 'party.blocks.rsvp.title',
        your_answer: 'party.blocks.rsvp.your_answer',
        closed: 'party.blocks.rsvp.closed',
        admin_link: 'party.blocks.rsvp.admin_link',
        is_comming: { label: 'party.blocks.rsvp.is_comming.label' },
        is_comming_amount: { label: 'party.blocks.rsvp.is_comming_amount.label' },
        not_comming: { label: 'party.blocks.rsvp.not_comming.label' },
        email_helper_text: 'party.blocks.rsvp.email_helper_text',
        new_message: 'party.blocks.rsvp.new_message',
        gdpr: 'party.blocks.rsvp.gdpr',
        has_submitted: 'party.blocks.rsvp.has_submitted',
        has_changed: 'party.blocks.rsvp.has_changed',
      },
      su: { label: 'party.blocks.su.label' },
      title: { title: 'party.blocks.title.title' },
      theme: {
        label: 'party.blocks.theme.label',
        legacy: { label: 'party.blocks.theme.legacy.label' },
        default: { label: 'party.blocks.theme.default.label' },
      },
    },
    wishlist: {
      title: 'party.wishlist.title',
      add_title: 'party.wishlist.add_title',
      edit_title: 'party.wishlist.edit_title',
      upload_image: 'party.wishlist.upload_image',
      label: { title: 'party.wishlist.label.title', link: 'party.wishlist.label.link' },
      delete_confirm: 'party.wishlist.delete_confirm',
      enable_reservations: 'party.wishlist.enable_reservations',
      login_to_reserve: 'party.wishlist.login_to_reserve',
      reserved_by: 'party.wishlist.reserved_by',
      reserve: 'party.wishlist.reserve',
      login_and_reserve: 'party.wishlist.login_and_reserve',
      remove_reservation: 'party.wishlist.remove_reservation',
      reserved_by_tooltip: 'party.wishlist.reserved_by_tooltip',
      fetch_tags: 'party.wishlist.fetch_tags',
      fetch_error: 'party.wishlist.fetch_error',
    },
    photos: {
      action: 'party.photos.action',
      title: 'party.photos.title',
      no_photos: 'party.photos.no_photos',
      make_public: 'party.photos.make_public',
      make_non_public: 'party.photos.make_non_public',
      select_file: 'party.photos.select_file',
      drag_to_here: 'party.photos.drag_to_here',
      upload_description: 'party.photos.upload_description',
      meta: 'party.photos.meta',
      delete: { confirm: 'party.photos.delete.confirm' },
    },
    actions: { wishlist: 'party.actions.wishlist' },
    footer: { create: 'party.footer.create' },
  },
  generic: {
    create: 'generic.create',
    submit: 'generic.submit',
    reply: 'generic.reply',
    sort_by: 'generic.sort_by',
    clear: 'generic.clear',
    delete: 'generic.delete',
    name: 'generic.name',
    email: 'generic.email',
    password: 'generic.password',
    phone: 'generic.phone',
    comment: 'generic.comment',
    cancel: 'generic.cancel',
    activate: 'generic.activate',
    remove: 'generic.remove',
    ok: 'generic.ok',
    save: 'generic.save',
    saved: 'generic.saved',
    update: 'generic.update',
    required: 'generic.required',
    edit: 'generic.edit',
    send: 'generic.send',
    close: 'generic.close',
    loading: 'generic.loading',
    unsaved_changes_confirm: 'generic.unsaved_changes_confirm',
    error: 'generic.error',
    error_try_again: 'generic.error_try_again',
    phone_error: 'generic.phone_error',
    phone_error_start_with: 'generic.phone_error_start_with',
    email_error: 'generic.email_error',
    unsaved_changes_loss: 'generic.unsaved_changes_loss',
    months: 'generic.months',
    all_prices_incl_tax: 'generic.all_prices_incl_tax',
    buy: 'generic.buy',
  },
  admin: {
    contacts: {
      delete: {
        confirm: 'admin.contacts.delete.confirm',
        already_received_invite: 'admin.contacts.delete.already_received_invite',
      },
      change: { already_received_invite: 'admin.contacts.change.already_received_invite' },
      copy_url: 'admin.contacts.copy_url',
      labels: {
        names: 'admin.contacts.labels.names',
        email: 'admin.contacts.labels.email',
        email_error: 'admin.contacts.labels.email_error',
        phone: 'admin.contacts.labels.phone',
        people: 'admin.contacts.labels.people',
      },
      create: 'admin.contacts.create',
      empty: 'admin.contacts.empty',
      people: { tooltip: 'admin.contacts.people.tooltip' },
      first_visit: 'admin.contacts.first_visit',
      has_received_invite: 'admin.contacts.has_received_invite',
      create_button: 'admin.contacts.create_button',
    },
    invitations: {
      dear_guest: 'admin.invitations.dear_guest',
      name: { new_empty: 'admin.invitations.name.new_empty' },
      title: { new_empty: 'admin.invitations.title.new_empty' },
      messages_label: 'admin.invitations.messages_label',
      create_label: 'admin.invitations.create_label',
      create_invitation: 'admin.invitations.create_invitation',
      create_message: 'admin.invitations.create_message',
      tip: 'admin.invitations.tip',
    },
    selection: {
      receives_email: 'admin.selection.receives_email',
      receives_text: 'admin.selection.receives_text',
      missing_method: 'admin.selection.missing_method',
    },
    message: {
      delete: 'admin.message.delete',
      warning_name_removed: 'admin.message.warning_name_removed',
      preview: 'admin.message.preview',
      tab: { email: 'admin.message.tab.email', text: 'admin.message.tab.text' },
      email: 'admin.message.email',
      text: 'admin.message.text',
      test: {
        menu: 'admin.message.test.menu',
        short_menu: 'admin.message.test.short_menu',
        title: 'admin.message.test.title',
        description: 'admin.message.test.description',
        description_verify_phone: 'admin.message.test.description_verify_phone',
        action: 'admin.message.test.action',
        success: 'admin.message.test.success',
        verify_phone: 'admin.message.test.verify_phone',
        verify_account: 'admin.message.test.verify_account',
        account_link: 'admin.message.test.account_link',
        sending_message: 'admin.message.test.sending_message',
      },
      send: {
        invitations: 'admin.message.send.invitations',
        message: 'admin.message.send.message',
        title: 'admin.message.send.title',
        subject: 'admin.message.send.subject',
        description: 'admin.message.send.description',
        success: 'admin.message.send.success',
        warning: 'admin.message.send.warning',
        error: 'admin.message.send.error',
        unsubscribed: 'admin.message.send.unsubscribed',
        details: 'admin.message.send.details',
        action: 'admin.message.send.action',
        pay_action: 'admin.message.send.pay_action',
        one_token_costs: 'admin.message.send.one_token_costs',
        action_without_sms: 'admin.message.send.action_without_sms',
        all_guests_already: 'admin.message.send.all_guests_already',
      },
      auto_save: 'admin.message.auto_save',
      send_to: {
        label: 'admin.message.send_to.label',
        all: 'admin.message.send_to.all',
        missing: 'admin.message.send_to.missing',
        custom: 'admin.message.send_to.custom',
        rsvp: 'admin.message.send_to.rsvp',
        without_rsvp: 'admin.message.send_to.without_rsvp',
        rsvp_yes: 'admin.message.send_to.rsvp_yes',
        rsvp_no: 'admin.message.send_to.rsvp_no',
      },
      sending_to: 'admin.message.sending_to',
      tokens_in_account: 'admin.message.tokens_in_account',
      tokens_used: 'admin.message.tokens_used',
      not_enough_tokens: 'admin.message.not_enough_tokens',
      sms_sender_tooltip: 'admin.message.sms_sender_tooltip',
      text_characters_tooltip: 'admin.message.text_characters_tooltip',
      text_characters_too_long: 'admin.message.text_characters_too_long',
      text_contains_url: 'admin.message.text_contains_url',
      text_link_with_rsvp: 'admin.message.text_link_with_rsvp',
      text_link_without_rsvp: 'admin.message.text_link_without_rsvp',
      text_link_tooltip: 'admin.message.text_link_tooltip',
    },
    rsvp: {
      delete: 'admin.rsvp.delete',
      connected: 'admin.rsvp.connected',
      fields: { title: 'admin.rsvp.fields.title' },
      title: 'admin.rsvp.title',
      column: {
        name: 'admin.rsvp.column.name',
        received: 'admin.rsvp.column.received',
        guests: 'admin.rsvp.column.guests',
        comment: 'admin.rsvp.column.comment',
        attending: 'admin.rsvp.column.attending',
      },
      create: 'admin.rsvp.create',
      see_who_not: 'admin.rsvp.see_who_not',
      empty: 'admin.rsvp.empty',
      edit: 'admin.rsvp.edit',
      no_missing: 'admin.rsvp.no_missing',
      field: {
        name: 'admin.rsvp.field.name',
        name_placeholder: 'admin.rsvp.field.name_placeholder',
        type: { text: 'admin.rsvp.field.type.text', choice: 'admin.rsvp.field.type.choice' },
        required_notice: 'admin.rsvp.field.required_notice',
        hidden: 'admin.rsvp.field.hidden',
        add: 'admin.rsvp.field.add',
      },
      stat: {
        attending: 'admin.rsvp.stat.attending',
        not_attending: 'admin.rsvp.stat.not_attending',
        missing: 'admin.rsvp.stat.missing',
      },
      reply_placeholder: 'admin.rsvp.reply_placeholder',
      count_messages: 'admin.rsvp.count_messages',
      show_all: 'admin.rsvp.show_all',
      cannot_reply: 'admin.rsvp.cannot_reply',
      filter: {
        received_date: 'admin.rsvp.filter.received_date',
        guest_count: 'admin.rsvp.filter.guest_count',
      },
      import: {
        button: 'admin.rsvp.import.button',
        header: 'admin.rsvp.import.header',
        file_input: 'admin.rsvp.import.file_input',
        label: 'admin.rsvp.import.label',
        helper_text: 'admin.rsvp.import.helper_text',
        do_button: 'admin.rsvp.import.do_button',
        placeholder: 'admin.rsvp.import.placeholder',
        error: 'admin.rsvp.import.error',
        success: 'admin.rsvp.import.success',
        delete_on_import: 'admin.rsvp.import.delete_on_import',
        delete_on_import_confirm: 'admin.rsvp.import.delete_on_import_confirm',
      },
      export: {
        menu: 'admin.rsvp.export.menu',
        csv: {
          all: 'admin.rsvp.export.csv.all',
          attending: 'admin.rsvp.export.csv.attending',
          rejected: 'admin.rsvp.export.csv.rejected',
        },
      },
    },
    settings: {
      title: 'admin.settings.title',
      remove: {
        title: 'admin.settings.remove.title',
        description: 'admin.settings.remove.description',
        textfield_label: 'admin.settings.remove.textfield_label',
        textfield_value: 'admin.settings.remove.textfield_value',
        confirm: 'admin.settings.remove.confirm',
      },
      custom_url: {
        label: 'admin.settings.custom_url.label',
        notice: 'admin.settings.custom_url.notice',
        current: 'admin.settings.custom_url.current',
        click_change: 'admin.settings.custom_url.click_change',
        error: 'admin.settings.custom_url.error',
        success: 'admin.settings.custom_url.success',
        description: 'admin.settings.custom_url.description',
      },
      photos: {
        title: 'admin.settings.photos.title',
        enabled: {
          switch: 'admin.settings.photos.enabled.switch',
          description1: 'admin.settings.photos.enabled.description1',
          description2: 'admin.settings.photos.enabled.description2',
        },
        enabled_date: {
          label: 'admin.settings.photos.enabled_date.label',
          description1: 'admin.settings.photos.enabled_date.description1',
        },
        public: {
          switch: 'admin.settings.photos.public.switch',
          description1: 'admin.settings.photos.public.description1',
        },
        public_default: {
          switch: 'admin.settings.photos.public_default.switch',
          description1: 'admin.settings.photos.public_default.description1',
        },
        download: {
          description: 'admin.settings.photos.download.description',
          description2: 'admin.settings.photos.download.description2',
          button: 'admin.settings.photos.download.button',
        },
        unlimited: {
          description1: 'admin.settings.photos.unlimited.description1',
          over_limit_description: 'admin.settings.photos.unlimited.over_limit_description',
          pay_action: 'admin.settings.photos.unlimited.pay_action',
          limit: 'admin.settings.photos.unlimited.limit',
          uploaded: 'admin.settings.photos.unlimited.uploaded',
          is_unlimited: 'admin.settings.photos.unlimited.is_unlimited',
          over_limit: 'admin.settings.photos.unlimited.over_limit',
        },
      },
      rsvp: {
        title: 'admin.settings.rsvp.title',
        on_off: {
          description: 'admin.settings.rsvp.on_off.description',
          label: 'admin.settings.rsvp.on_off.label',
        },
        restricted: {
          description: 'admin.settings.rsvp.restricted.description',
          description2: 'admin.settings.rsvp.restricted.description2',
          label: 'admin.settings.rsvp.restricted.label',
        },
        off_su: {
          description: 'admin.settings.rsvp.off_su.description',
          description2: 'admin.settings.rsvp.off_su.description2',
          label: 'admin.settings.rsvp.off_su.label',
        },
        guest_count: {
          description: 'admin.settings.rsvp.guest_count.description',
          description2: 'admin.settings.rsvp.guest_count.description2',
        },
        extra: {
          title: 'admin.settings.rsvp.extra.title',
          description: 'admin.settings.rsvp.extra.description',
        },
      },
      language: {
        description: 'admin.settings.language.description',
        label: 'admin.settings.language.label',
        en: 'admin.settings.language.en',
        da: 'admin.settings.language.da',
      },
      cohosts: {
        title: 'admin.settings.cohosts.title',
        description: 'admin.settings.cohosts.description',
        invite_cohost: 'admin.settings.cohosts.invite_cohost',
        only_owner: 'admin.settings.cohosts.only_owner',
        cohost_invited: 'admin.settings.cohosts.cohost_invited',
        tooltip_accepted: 'admin.settings.cohosts.tooltip_accepted',
        tooltip_sent: 'admin.settings.cohosts.tooltip_sent',
        confirm_delete: 'admin.settings.cohosts.confirm_delete',
        needs_login: 'admin.settings.cohosts.needs_login',
        confirm: 'admin.settings.cohosts.confirm',
        error_token: 'admin.settings.cohosts.error_token',
        confirm_description: 'admin.settings.cohosts.confirm_description',
      },
      sms_sender: {
        title: 'admin.settings.sms_sender.title',
        description1: 'admin.settings.sms_sender.description1',
        switch: 'admin.settings.sms_sender.switch',
      },
      visibility: {
        title: 'admin.settings.visibility.title',
        open: 'admin.settings.visibility.open',
        password: 'admin.settings.visibility.password',
        restricted: 'admin.settings.visibility.restricted',
      },
    },
  },
  titles: {
    contacts: 'titles.contacts',
    settings: 'titles.settings',
    user: 'titles.user',
    invitations: 'titles.invitations',
  },
  type: {
    party: 'type.party',
    birthday: 'type.birthday',
    wedding: 'type.wedding',
    baptism: 'type.baptism',
    confirmation: 'type.confirmation',
    anniversary: 'type.anniversary',
    christmas: 'type.christmas',
  },
  heroImage: { group: { colors: 'heroImage.group.colors' } },
  wizard: {
    title: 'wizard.title',
    change_type: 'wizard.change_type',
    change_this: 'wizard.change_this',
    template: {
      party: 'wizard.template.party',
      birthday: 'wizard.template.birthday',
      wedding: 'wizard.template.wedding',
      baptism: 'wizard.template.baptism',
      confirmation: 'wizard.template.confirmation',
      anniversary: 'wizard.template.anniversary',
      christmas: 'wizard.template.christmas',
    },
    creator: {
      party: {
        title: 'wizard.creator.party.title',
        description: 'wizard.creator.party.description',
        body: 'wizard.creator.party.body',
      },
      birthday: {
        title: 'wizard.creator.birthday.title',
        description: 'wizard.creator.birthday.description',
        body: 'wizard.creator.birthday.body',
      },
      wedding: {
        title: 'wizard.creator.wedding.title',
        description: 'wizard.creator.wedding.description',
        body: 'wizard.creator.wedding.body',
      },
      baptism: {
        title: 'wizard.creator.baptism.title',
        description: 'wizard.creator.baptism.description',
        body: 'wizard.creator.baptism.body',
      },
      confirmation: {
        title: 'wizard.creator.confirmation.title',
        description: 'wizard.creator.confirmation.description',
        body: 'wizard.creator.confirmation.body',
      },
      anniversary: {
        title: 'wizard.creator.anniversary.title',
        description: 'wizard.creator.anniversary.description',
        body: 'wizard.creator.anniversary.body',
      },
      christmas: {
        title: 'wizard.creator.christmas.title',
        description: 'wizard.creator.christmas.description',
        body: 'wizard.creator.christmas.body',
      },
    },
  },
  help: {
    description: 'help.description',
    comment: 'help.comment',
    party: 'help.party',
    party_no_choice: 'help.party_no_choice',
    success: 'help.success',
  },
};
