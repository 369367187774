export const stripDomain = (url: string) => {
  return url.replace(/^https?:\/\/(www\.)?/, '').replace(/\/$/, '');
};
export const truncate = (str: string, chars: number, ellipsis = '…') => {
  return str.length > chars ? str.slice(0, chars - 1) + ellipsis : str;
};

export const safeRegex = (text: string) => text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
export const replaceAll = (text: string, search: string, replace: string) =>
  text.replace(new RegExp(safeRegex(search), 'g'), replace);

export const nameSuffixS = (name: string) => {
  name = name.trim();
  const endsOnS = name.endsWith('s');
  return name + (endsOnS ? "'" : 's');
};

export const formatCurrency = (amount: number, currency: 'eur' | 'dkk', simple = false) => {
  const digits = (amount / 100).toFixed(2).replace('.00', '');

  switch (currency) {
    case 'eur':
      return simple ? `${digits}` : `€ ${digits}`;

    case 'dkk':
      return simple ? `${digits},-` : `DKK ${digits},-`;
  }
};
